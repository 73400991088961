const Util = window.Util;

const loadingQuery = {
  ajax: false,
  swagger_auth: false,
};

let ajax;
// Аутентификация через swagger-openapi
let swagger_auth;

function GetURLParameter(sParam) {
  var sPageURL = window.location.search.substring(1);
  var sURLVariables = sPageURL.split('&');

  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] == sParam) {
      return sParameterName[1];
    }
  }
}

if (GetURLParameter('state')&&GetURLParameter('code')) {
  $.ajax({
    type: 'post',
    url: Util.blackbox('/auth/cookies/'),
    data: {
      state: GetURLParameter('state'),
      code: GetURLParameter('code')
    },
    success: function success(data) {
      if (data.status == 1) {

				if ($('#error_msg').is(':visible')) {
					$('#error_msg').slideUp(200);
				}
      Cookie.set('session_id', data.session_id, {
        expires: 86400,
        path: '/',
        domain: data.host
      });
      Cookie.set('session_username', data.session_username, {
        expires: 86400,
        path: '/',
        domain: data.host
      });
      Cookie.set('debug', data.debug, {
        expires: 86400,
        path: '/',
        domain: data.host
      });
      location.href = '/';
    } else {
      $('#error_msg').text(data.error);
				if (!$('#error_msg').is(':visible')) {
					$('#error_msg').slideDown(200);
				} else {
					$('#error_msg').fadeTo(150, .3, function () {
						$(this).fadeTo(150, 1, function () {
							$(this).fadeTo(150, .3, function () {
								$(this).fadeTo(150, 1);
							});
						});
					});
				}
    }
  }
  });
}

$('.btn-esia').click(function(event){
  $("body").css("cursor", "wait");
  $.ajax({
    type: 'get',
    url: Util.blackbox('/auth/esia/'),
    success: function success(data) {
      $("body").css("cursor", "default");
      location.href=data;
    },
    error: function error(err) {
      console.log(err);
    }
  });
});

$('form').submit(function(event) {
	event.preventDefault();

  $('button[type=submit]').find('i').removeClass('fa-arrow-right').addClass('fa-cog fa-spin');

  function isLoadingComplete() {
    return loadingQuery.ajax && loadingQuery.swagger_auth;
  }


	if(ajax) {
		ajax.abort();
  }

  if (swagger_auth) {
    swagger_auth.abort();
  }

	swagger_auth = $.ajax({
		type: 'POST',
    url: Util.blackbox('/swagger/auth/session'),
    contentType: "application/json; charset=UTF-8",
    data: JSON.stringify({
            username: $('[name=username]', this).val(),
            password: $('[name=password]', this).val(),
          }),
    complete: function() {
      loadingQuery.swagger_auth = true;
      if (isLoadingComplete()) {
        location.reload();
      }
    }
  });

	ajax = $.ajax({
		type: 'post',
		url: $('button[type=submit]').data('href'),
		data: $(this).serialize(),
		success: function(data) {
			/**
			 * @param {Object} data
			 * @param {number} data.status
			 * @param {string} [data.session_id]
			 * @param {string} [data.session_username]
			 * @param {string} [data.host]
			 * @param {string} [data.debug=0]
			 * @param {string} [data.error]
			 */
			if (data.status == 1) {

				if ($('#error_msg').is(':visible')) {
					$('#error_msg').slideUp(200);
				}

				Cookie.set('session_id', data.session_id, {
					expires: 86400,
					path: '/',
					domain: data.host
				});

				Cookie.set('session_username', data.session_username, {
					expires: 86400,
					path: '/',
					domain: data.host
				});

				Cookie.set('debug', data.debug, {
					expires: 86400,
					path: '/',
					domain: data.host
        });

        loadingQuery.ajax = true;

		    if (isLoadingComplete()) {
          if (location.href.match('login')) {
            location.href = '/';
          } else {
            location.reload();
          }
        }

			} else {
				$('button[type=submit]').find('i').removeClass('fa-cog fa-spin').addClass('fa-arrow-right');
				$('#error_msg').text(data.error);
				if (!$('#error_msg').is(':visible')) {
					$('#error_msg').slideDown(200);
				} else {
					$('#error_msg').fadeTo(150, .3, function () {
						$(this).fadeTo(150, 1, function () {
							$(this).fadeTo(150, .3, function () {
								$(this).fadeTo(150, 1);
							});
						});
					});
				}
			}
		}
	});
});